import './index.css';
import './App.css';
import * as styles from './Home.module.css'
import logo from '../images/CC-001.png';

import cloud2 from '../images/cloud2.png';
import planet from '../images/planet.png';
import spacekitty from '../images/spacekitty.png';

import React, { Component } from "react";

import gsap from 'gsap'

export default class Confirm extends Component {
  constructor() {
    super();

    this.state = {
      active: false,
      bgcolor: 'darkred',
      fgcolor: 'white'
    }
  }

  componentDidMount() {
    gsap.from("#top-logo", {
      duration: 3,
      opacity: 0,
      yPercent: -100
    });

  }

  componentWillUnmount() {
  }

  render() {

    const style = {
      backgroundColor: this.state.bgcolor,
      color: this.state.fgcolor
    }
    return (
      <div>
        <div style={style} className={styles.container}>
          <main className={styles.main}>

          Order Confirmed, thank you for not being broke.
            <img src={spacekitty} className={styles.spacekitty1} alt="" />
            <img src={planet} className={styles.planet1} alt="" />
            <div id="top-logo" >
              <img className={styles.rogo} src={logo} alt="" width={400} height={400} />

              <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

            </div>
            <div>
              <img className={styles.cloud1} src={cloud2} alt="" />
              <img className={styles.cloud3} src={cloud2} alt="" />
              <img className={styles.cloud4} src={cloud2} alt="" />
              <img className={styles.cloud5} src={cloud2} alt="" />
              <img className={styles.cloud6} src={cloud2} alt="" />
              <img className={styles.cloud7} src={cloud2} alt="" />
            </div>
            <div className={styles.description}>

              <div id="club" className={styles.title}>
                Cozy Club&trade;
              </div>
              <br />Our mission is to provide
              <br />comfortable garments
              <br />to the community as a whole.
              <br />
              <br />Fabrics that can be worn inside and out.<br />
              <br />We donate a pair of socks
              <br /> for EVERY order
              <br />
              <br />Please reach out to us!
              <br />
              <br />Homeless shelters
              <br />Community outreach
              <br />General inquiries
            </div>
          </main>


          <a
            href="https://instagram.com/cozierclothing"
          >
            <footer className={styles.footer}>
              Cozier Clothing LLC
            </footer>
          </a>

        </div>
      </div>
    );
  }
}